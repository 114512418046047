<template>
  <div class="deviceBill">
    <div class="pageHeader">
        <span>信息反馈</span>
        <div class="pageHeaderBtns">
            <a-button type="primary" class="leftBtn" @click="submit">反馈</a-button>
            <a-button @click="backTo">返回</a-button>
        </div>
    </div>
    <div class="pageContent">
        <a-form-model
            ref="ruleForm"
            :model="formData"
            :rules="rules"
        >
            <a-form-model-item label="信息标题" required prop="newsTitle" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
                <a-input v-model="formData.newsTitle" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="附件" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
            </a-form-model-item>
            <a-form-model-item label=""  :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
                <UploadFile @setFile="setFile" :fileList="fileList"/>
                <span class="uploadRemark">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</span>
            </a-form-model-item>
            <a-form-model-item label="信息正文" required :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
            </a-form-model-item>
            <a-form-model-item label="" prop="newsInfo" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 23, offset: 0}" labelAlign="left">
                <Editor @setEditContent="setEditContent" :editorData="editorData"/>
            </a-form-model-item>
        </a-form-model>
    </div>
  </div>
</template>

<script>
import Editor from '@/component/editorArea/editorArea'
import UploadFile from '@/component/uploadFile/uploadFile1'
import {
    updateBackByMsgCode
} from '@/api/newsIncorruptible'
export default {
  name: "newsInfo",
  data() {
    return {
        fileList : [],
        editorData : '',
        formData : {
            newsTitle : '',
        },
        rules : {
            newsTitle : [{ require : true,  message: '请输入信息标题', trigger: 'blur'}],
            newsInfo : [{ require : true,  message: '请输入信息正文', trigger: 'blur'}]
        }
    };
  },
  components: {
    Editor,
    UploadFile
  },
  created() {
    
  },
  methods: {
    backTo(){
        this.$router.go(-1)
    },
    setEditContent(val){
        this.editorData = val
    },
    setFile(val){
        this.fileList = val
    },
    submit(){
        let fileUrl = [],fileName = []
        this.fileList.map(item=>{
            fileUrl.push(item.url)
            fileName.push(item.name)
        })
        let data = {
            receiveCode : this.$route.query.code,
            receiveTitle : this.formData.newsTitle,
            receiveContent : this.editorData,
            receiveFile : fileUrl.join(','),
            receiveFileName : fileName.join(',')
        }
        updateBackByMsgCode(data).then(res=>{
            if(res.data.code == 200){
                this.openNotification('success', res.message)
                this.backTo()
            }else{
                this.openNotification('error', res.message)
            }
        })
    },
    openNotification: function(type, msg) {
        this.$notification[type]({
            message: "系统提示",
            description: msg,
        });
    },
  },
};
</script>

<style lang="less" scoped>
.searchIpt{
    width: 200px;
}
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  justify-content: space-between;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
  .pageHeaderBtns{
      margin-right: 27px;
      .leftBtn{
          margin-right: 10px;
      }
  }
}
.pageContent{
    margin-top: 20px;
    background: white;
    padding: 27px;
}
.uploadRemark{
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-family: PingFangSC-regular;
}
</style>
