<template>
  <div class="clearfix">
    <!--       -->
    <a-upload
      name="file"
      :multiple="false"
      :fileList="fileListList"
      :action="uploadUrl"
      :headers="headers"
      @change="handleChange"
      @preview="handlePreview"
    >
      <a-button> <a-icon type="upload" />上传文件</a-button>
    </a-upload>
  </div>
</template>

<script>
/*
  用于文件上传

  引入 import UploadFile from "@/component/uploadFile/uploadFile";
  使用 <UploadFile @setFile="setFile" :fileList="fileList"/>
  setFile 组件返回的值 fileList 显示的文件数组
  data(){
    return{
      fileList:[]
    }
  }
  methods:{
    setFile(val){
      this.fileList=val
    }
  }
*/
import { API_HOST } from "@/server/network1";

export default {
  name: "UpdataImage",
  data() {
    return {
      uploadUrl: API_HOST + "/school/goApply/upload",
      API_HOST,
      fileListList: [],
      imageUrl: [],
    };
  },
  props: {
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    fileList: {
      handler(value, oldValue) {
        let that = this;
        let array = [];
        console.log(value, "1123");
        if (value.indexOf("") == -1) {
          value.forEach((item, index) => {
            let json = {
              uid: index + 1,
              name: item.name || item.response.data.name,
              status: "done",
              url: item.url || item.thumbUrl || item.response.data.url || "",
            };
            array.push(json);
          });
          that.fileListList = array;
          this.imageUrl = value;
        }
      },
    },
  },
  computed: {
    headers() {
      return {
        Authorization: localStorage.getItem("jx_token"),
      };
    },
  },
  created() {},
  components: {},
  mounted() {},
  methods: {
    handlePreview(file) {
      // console.log(file, "file");
      // window.open(file.url)
      if (file.name.indexOf(".zip") > -1 || file.name.indexOf(".rar") > -1) {
        return window.open(file.url);
      } else {
        let Base64 = require("js-base64").Base64;
        return window.open(
          "https://smart-futuresc-kkfileview.ictsoft.cn/onlinePreview?url=" +
            encodeURIComponent(Base64.encode(file.url))
        );
      }
    },
    handleChange({ fileList }) {
      this.fileListList = fileList;
      console.log(fileList);
      this.$nextTick(() => {
        let imgPic = fileList.map((item) => {
          return {
            url: item.url
              ? item.url
              : item.response
              ? item.response.data.url
              : "",
            name: item.name
              ? item.name
              : item.response
              ? item.response.data.name
              : "",
          };
          // return item.url ? item.url : item.response ? item.response.data.url : ""
        });
        this.imageUrl = imgPic;
        this.$emit("setFile", imgPic);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.picList {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: #101010;
    cursor: pointer;
  }
  .addFile {
    color: #3985f9;
    font-size: 29px;
    cursor: pointer;
  }
  img {
    margin-right: 8px;
    width: 80px;
    height: 80px;
  }
}
</style>
